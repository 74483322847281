<template>
  <section>
    <forgot-password-form />
  </section>
</template>

<script>
export default {
  name: 'ForgotPassword',
  components: {
    ForgotPasswordForm: () => import('@/components/Access/ForgotPasswordForm.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
